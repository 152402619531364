var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hero" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.image
          ? _c("broker-hero-background", { attrs: { image: _vm.image } })
          : _vm._e(),
        _vm._v(" "),
        _vm.showHeroCtaVariation
          ? _c("broker-hero-cta-variation", {
              staticClass: "cta",
              attrs: {
                title: _vm.title,
                "sub-title": _vm.subTitle,
                usps: _vm.usps,
                "trustpilot-data": _vm.trustpilotData,
              },
            })
          : _c("broker-hero-cta", {
              staticClass: "cta",
              attrs: {
                title: _vm.title,
                "sub-title": _vm.subTitle,
                usps: _vm.usps,
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }