<template>
  <div class="hero">
    <div class="content">
      <broker-hero-background
        v-if="image"
        :image="image"
      />

      <broker-hero-cta-variation
        v-if="showHeroCtaVariation"
        :title="title"
        :sub-title="subTitle"
        :usps="usps"
        :trustpilot-data="trustpilotData"
        class="cta"
      />

      <broker-hero-cta
        v-else
        :title="title"
        :sub-title="subTitle"
        :usps="usps"
        class="cta"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BrokerHero',
    components: {
      BrokerHeroBackground: () => import(/* webpackChunkName: 'elements/broker/broker-hero-image' */
        './BrokerHeroBackground'),
      BrokerHeroCtaVariation: () => import(/* webpackChunkName: 'elements/broker/broker-her-cta-variation' */
        './BrokerHeroCtaVariation'),
      BrokerHeroCta: () => import(/* webpackChunkName: 'elements/broker/broker-hero-cta' */
        './BrokerHeroCta')
    },
    props: {
      title: {
        type: String,
        required: false,
        default: null
      },
      subTitle: {
        type: String,
        required: false,
        default: null
      },
      usps: {
        type: Array,
        required: false,
        default: null
      },
      image: {
        type: Object,
        required: false,
        default: () => {}
      },
      trustpilotData: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    computed: {
      showHeroCtaVariation () {
        return this.SWEDEN
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hero {
    .content {
      position: relative;
      background-color: #f2f2f2;
    }

    .cta {
      position: relative;
      z-index: 2;
    }
  }
</style>
